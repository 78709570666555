import React from 'react';
// import CourseList from "./CourseList";
import Sidebar from './Sidebar';
import YouTubeContent from './YouTubeContent';
import { Helmet } from 'react-helmet-async';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Home Page</title>
        <meta property='og:title' content='Learn with Vinod' />
        <meta
          property='og:description'
          content='Welcome to `Learn with Vinod`, a platform made for my students. A collection of resources, posts and tutorials, links to my Github repositories and YouTube videos.'
        />
        <meta
          property='og:image'
          content='https://vinod.co/assets/vinod.jpeg'
        />
        <meta property='og:url' content='https://vinod.co/' />
      </Helmet>
      <div className='row'>
        <div className='col-sm-12 col-md-8 ' style={styles.courseList}>
          {/* <CourseList /> */}
          <YouTubeContent />
        </div>
        <div className='col-sm-12 col-md-4 ' style={styles.sidebar}>
          <Sidebar />
        </div>
      </div>
    </>
  );
};

export default HomePage;
const styles = {
  postsWidgetContainer: {
    marginRight: 20,
  },
  sidebar: {
    height: '90vh',
    overflow: 'auto',
  },
  courseList: {
    height: '90vh',
    overflow: 'auto',
  },
};
