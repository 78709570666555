import React from 'react';

const Footer = () => {
  return (
    <>
      <hr />
      <div className='text-center'>
        <div className='lead mb-3'>
          &copy; 2024 - All rights reserved by{' '}
          <a href='https://vinod.co'>
            Learn with <em>Vinod</em>
          </a>
        </div>
        <div className=' mb-3'>
          <h5>ಸಿರಿಗನ್ನಡಂ ಗೆಲ್ಗೆ ಸಿರಿಗನ್ನಡಂ ಬಾಳ್ಗೆ</h5>
        </div>
      </div>
    </>
  );
};

export default Footer;
