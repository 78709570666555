import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';

const Header = () => (
  <>
    <nav className='navbar navbar-expand-lg bg-body-tertiary mb-5'>
      <div className='container'>
        <Link className='navbar-brand' to='/'>
          Learn with <em>Vinod</em>{' '}
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            <li className='nav-item'>
              <Link className='nav-link' to='/tutorials'>
                <i className='bi bi-book'></i> Tutorials
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/posts'>
                <i className='bi bi-mailbox'></i> Posts
              </Link>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                href='https://snippets-3d494.firebaseapp.com'
                target='_blank'
                rel='noreferrer'
              >
                <i className='bi bi-file-earmark-code'></i> Snippets
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                href='https://www.youtube.com/@LearnWithVinod?sub_confirmation=1'
                target='_blank'
                rel='noreferrer'
              >
                <i className='bi bi-youtube'></i> Youtube
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                href='https://github.com/vinod-kayartaya?tab=repositories'
                target='_blank'
                rel='noreferrer'
              >
                <i className='bi bi-github'></i> Github
              </a>
            </li>
            {/* <li className='nav-item'>
              <a
                className='nav-link'
                href='https://github.com/kayartaya-vinod?tab=repositories'
                target='_blank'
                rel='noreferrer'
              >
                <i className='bi bi-github'></i> Github (old)
              </a>
            </li> */}
            <li className='nav-item'>
              <a
                className='nav-link'
                href='https://www.linkedin.com/in/javatrainer/'
                target='_blank'
                rel='noreferrer'
              >
                <i className='bi bi-linkedin'></i> LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    {/* <SearchBar /> */}
  </>
);

export default Header;
